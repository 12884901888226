<template>
    <div v-if="topic.id" class="web-app-topics">
        <side-bar v-if="!isLoggedIn" />

        <div class="container main-content">
            <div class="row no-gutters topic-view-header">
                <div class="col-auto topic-back">
                    <back-button />
                </div>
                <div class="col topic-title-and-memos-total">
                    <h1>{{ topic.name }}</h1>
                    <div class="total-memos">
                        <strong>{{ topic.totalMemos }} </strong>
                        <span>Memos</span>
                    </div>
                </div>
                <div class="col-auto topic-options">
                    <follow-entity-button
                        use-api-v2
                        :entity-id="topic.id"
                        :is-following="Number(topic.is_following)"
                        entity="topics"
                        @toggled-follow="(is_following) => topic.is_following = is_following"
                    />
                </div>
            </div>
            <memod-list :list-endpoint="listEndpoint" use-api-v2 />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "TopicView",
    components: {
        FollowEntityButton: () => import(/* webpackChunkName: "follow-entity-button" */ "@c/atoms/follow-entity-button"),
        BackButton: () => import(/* webpackChunkname: "back-button" */ "@c/atoms/back-button"),
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar")
    },
    data() {
        return {
            topic: {
                id: 0,
                is_following: false,
                name: "",
                slug: "",
                totalMemos: 0
            }
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        topicSlug() {
            return this.$route.params.topicSlug;
        },
        listEndpoint() {
            return `/topics/${this.topicSlug}/feeds?format=true`;
        }
    },
    created() {
        this.fetchSlug();
    },
    methods: {
        fetchSlug() {
            this.$emit("is-loading", true);
            window.scrollTo(0, 0);
            axiosV2.get(`topics/${this.topicSlug}`).then(({ data }) => {
                this.topic = data;
            }).catch((error) => {
                console.log(error.response.data.message);
            }).finally(() => {
                this.$emit("is-loading", false);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-topics {
    .main-content {
        padding-top: 100px;
        margin-bottom: 120px;

        .back-button {
            margin-right: 40px;
        }

        .topic-view-header {
            margin-bottom: 40px;

            .topic-title-and-memos-total {

                h1 {
                    padding-top: 7px;
                    margin-bottom: 6px;
                }

                .total-memos {
                    @extend .body-text;
                    display: flex;
                    align-items: baseline;
                    line-height: 14px;
                    color: rgba(235, 235, 245, 0.6);
                    margin-right: 10px;

                    strong {
                        color: white;
                        font-weight: 600;
                        display: block;
                        margin-right: 5px;
                    }
                }
            }
        }

        @media(max-width: $md) {
            padding-top: 20px;

            .topic-view-header {
                position: relative;

                .topic-back {
                    order: 1;
                    flex-basis: 100%;
                }

                .topic-title-and-memos-total {
                    order: 3;

                    h1 {
                        text-align: center;
                    }

                    .total-memos {
                        justify-content: center;
                    }
                }

                .topic-options {
                    order: 2;
                    flex: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}
</style>
